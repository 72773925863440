import { Box, Grid, styled } from '@mui/material';
import { Body2, Caption, ehiTheme } from '@ehi/ui';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';

export const RateAndBillingSectionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${ehiTheme.spacing(3)};
`;

export const RateAndBillingLabel = styled(Caption2)`
  display: inline;
`;

export const RateAndBillingText = styled(Body2)`
  display: inline;
  font-weight: bold;
  padding-left: ${ehiTheme.spacing(1)};
`;

export const RateSourceInfoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  padding: ${ehiTheme.spacing(2)};
  margin-top: ${ehiTheme.spacing(2)};
`;

export const RateSourceLink = styled(Caption, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{
  disabled?: boolean;
}>(({ disabled = false }) => {
  const color = disabled ? '#00000061' : '#4d789d';
  const cursor = disabled ? 'unset' : 'pointer';
  return {
    display: 'flex',
    alignItems: 'center',
    cursor,
    color,
  };
});

export const RateSourceSearchContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: ${ehiTheme.spacing(0, 2, 2)};
`;
RateSourceSearchContainer.defaultProps = {
  container: true,
  spacing: 2,
};

export const AccountNumberSearchContainer = styled(RateSourceSearchContainer)`
  background: #f5f5f5;
  margin-top: auto;
`;

export const RateSourceField = styled(Grid)`
  display: flex;
`;
RateSourceField.defaultProps = {
  container: true,
};

export const AccountListItem = styled('div')`
  padding: ${ehiTheme.spacing(1, 2)};
`;

export const StyledAccountsListBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'containerHeight',
})<{ containerHeight: number }>((props) => ({
  backgroundColor: '#ffffff',
  paddingTop: ehiTheme.spacing(2),
  height: props.containerHeight,
  position: 'relative',
}));
