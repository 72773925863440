import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { ACCEPT, CONTENT_TYPE_BUSINESS_ACCOUNT_RESERVATION_FLOWS } from 'services/headerConstants';
import {
  ReservationAccount,
  BusinessAccountSearchResult,
  PaginationResponse,
  SearchAccountRequest,
} from 'services/businessAccount/businessAccountTypes';

const BUSINESS_ACCOUNT_PATH = '/businessAccount';
const ACCOUNT_PATH = '/account';

export const retrieveAccount = async (accountNumber: string): Promise<ReservationAccount> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance?.get(`${BUSINESS_ACCOUNT_PATH}${ACCOUNT_PATH}/${accountNumber}`, {
    genericUrl: `${BUSINESS_ACCOUNT_PATH}${ACCOUNT_PATH}/{accountNumber}`,
    headers: {
      [ACCEPT]: [CONTENT_TYPE_BUSINESS_ACCOUNT_RESERVATION_FLOWS],
    },
  });
  return response?.data;
};

export const searchBusinessAccounts = async (
  payload: SearchAccountRequest
): Promise<{
  pagination?: PaginationResponse;
  businessAccounts?: BusinessAccountSearchResult[];
}> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance?.post(`${BUSINESS_ACCOUNT_PATH}/search-account`, payload, {
    genericUrl: `${BUSINESS_ACCOUNT_PATH}/search-account`,
  });
  return response?.data;
};
