import {
  AirportBranchSearchCriteria,
  BranchTypeSearch,
  BusinessType,
  GroupSummary,
} from 'services/location/locationTypes';
import {
  BranchLookupFields,
  BranchLookupValues,
  GroupOptionType,
  RentalBrand,
  SearchByType,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import { Brand, EMPTY_VALUE } from 'utils/constants';
import { TFunction } from 'i18next';
import { EHI_DOMAINS, generateReferenceUrn } from './urnUtils';

export enum DisplayedLocationTypes {
  Airport = 'airport',
  Exotics = 'exotics',
  HomeCity = ' homeCity',
  Port = 'port',
  TrainStation = 'trainStation',
  Truck = 'truck',
}

export const DAILY_RENTAL = 'DAILY_RENTAL';

export const getGroupOptions = (groups?: GroupSummary[]): GroupOptionType[] => {
  return groups && Array.isArray(groups)
    ? groups.map((group: GroupSummary, index: number) => ({
        name: group.description,
        index: index + 1,
        urn: group.urn,
      }))
    : [];
};

export const getBrandOptions = (brands?: RentalBrand[]) => {
  return brands ? brands.map(({ code }: { code: string }) => code) : [Brand.ENTERPRISE, Brand.ALAMO, Brand.NATIONAL];
};

export enum DistanceValues {
  TEN = '10',
  TWENTY_FIVE = '25',
  FIFTY = '50',
  HUNDRED = '100',
}

export const distances = (distanceText: string) => [
  { name: '10 '.concat(distanceText), value: DistanceValues.TEN },
  { name: '25 '.concat(distanceText), value: DistanceValues.TWENTY_FIVE },
  { name: '50 '.concat(distanceText), value: DistanceValues.FIFTY },
  { name: '100 '.concat(distanceText), value: DistanceValues.HUNDRED },
];

export const brands = [
  { title: 'Alamo', key: 'Alamo' },
  { title: 'Enterprise', key: 'Enterprise' },
  { title: 'National', key: 'National' },
];

export const locationTypeDropdownOptions = (t: TFunction<'translation'>) => [
  { name: t('location.airport'), code: DisplayedLocationTypes.Airport },
  { name: t('location.exotics'), code: DisplayedLocationTypes.Exotics },
  { name: t('location.homeCity'), code: DisplayedLocationTypes.HomeCity },
  { name: t('location.port'), code: DisplayedLocationTypes.Port },
  { name: t('location.trainStation'), code: DisplayedLocationTypes.TrainStation },
  { name: t('location.truck'), code: DisplayedLocationTypes.Truck },
];

export const getBranchLookupInitialValues = (
  defaultCountry: string,
  groupOptions: GroupOptionType[] | [],
  brandOptions: string[],
  t: TFunction<'translation'>
): BranchLookupValues => {
  return {
    [BranchLookupFields.SearchBy]: SearchByType.Keyword,
    [BranchLookupFields.Country]: defaultCountry,
    [BranchLookupFields.Groups]: groupOptions,
    [BranchLookupFields.LocationTypes]: locationTypeDropdownOptions(t),
    [BranchLookupFields.Brands]: brandOptions,
    [BranchLookupFields.Distance]: DistanceValues.TWENTY_FIVE,
    [BranchLookupFields.IsListButtonSelected]: true,
    [BranchLookupFields.IsMapButtonSelected]: false,
    [BranchLookupFields.SearchInputValue]: EMPTY_VALUE,
    [BranchLookupFields.SelectedBranch]: undefined,
  };
};

export const getBranchTypes = (locationTypes: string[]) => {
  if (
    (locationTypes.includes(DisplayedLocationTypes.HomeCity) ||
      locationTypes.includes(DisplayedLocationTypes.Airport)) &&
    locationTypes.includes(DisplayedLocationTypes.Truck)
  ) {
    return [BranchTypeSearch.DAILY_RENTAL, BranchTypeSearch.TRUCK_RENTAL];
  } else if (locationTypes.includes(DisplayedLocationTypes.Truck)) {
    return [BranchTypeSearch.TRUCK_RENTAL];
  } else if (locationTypes.includes(DisplayedLocationTypes.HomeCity)) {
    return [BranchTypeSearch.DAILY_RENTAL];
  } else {
    return;
  }
};

export const getAirportType = (locationTypes: string[]) => {
  if (locationTypes?.length === 1 && locationTypes.includes(DisplayedLocationTypes.Airport)) {
    return AirportBranchSearchCriteria.ONLY_AIRPORTS;
  } else if (
    locationTypes.includes(DisplayedLocationTypes.HomeCity) &&
    !locationTypes.includes(DisplayedLocationTypes.Airport)
  ) {
    return AirportBranchSearchCriteria.EXCLUDE_AIRPORTS;
  } else if (
    !locationTypes.includes(DisplayedLocationTypes.HomeCity) &&
    locationTypes.includes(DisplayedLocationTypes.Airport)
  ) {
    return AirportBranchSearchCriteria.ONLY_AIRPORTS;
  } else {
    return;
  }
};

const generateBusinessIndicatorUrn = (indicator: string, defaultEhiDatabase: string) => {
  return generateReferenceUrn(
    EHI_DOMAINS.location.name,
    EHI_DOMAINS.location.businessIndicator,
    indicator,
    defaultEhiDatabase
  );
};

export const getBusinessIndicators = (locationTypes: string[], defaultEhiDatabase: string) => {
  const businessIndicators = locationTypes
    .map((type) => {
      switch (type) {
        case DisplayedLocationTypes.Exotics:
          return generateBusinessIndicatorUrn(BusinessType.EXOTIC_CAR_LOCATION, defaultEhiDatabase);
        case DisplayedLocationTypes.Port:
          return generateBusinessIndicatorUrn(BusinessType.PORT_OF_CALL, defaultEhiDatabase);
        case DisplayedLocationTypes.TrainStation:
          return generateBusinessIndicatorUrn(BusinessType.TRAIN_STATION, defaultEhiDatabase);
        default:
          return;
      }
    })
    .filter((item) => !!item) as string[];
  return businessIndicators?.length > 0 ? businessIndicators : undefined;
};
