export enum AccountSearchFields {
  Country = 'country',
  Groups = 'groups',
  AccountName = 'accountName',
  AccountTypes = 'accountTypes',
  PhoneNumber = 'phoneNumber',
}

export type GroupOptionType = { name: string | undefined; index: number; urn: string | undefined };

export interface AccountSearchValues {
  [AccountSearchFields.Country]: string;
  [AccountSearchFields.Groups]: GroupOptionType[] | [];
  [AccountSearchFields.AccountName]: string;
  [AccountSearchFields.AccountTypes]: string[] | [];
  [AccountSearchFields.PhoneNumber]: string;
}
