import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Body2, EhiButton, ehiTheme } from '@ehi/ui';
import { Box } from '@mui/material';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { CountrySearchDropDown } from 'components/shared/forms/CountrySearchDropDown';
import { GroupDropDown } from 'components/shared/forms/GroupDropDown';
import { MaxLengthTextField } from 'components/shared/forms/MaxLengthTextField';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import { piiField } from '@ehi/support';
import { useTranslations } from 'components/shared/i18n';
import { FormProvider, useForm } from 'react-hook-form';
import { PrimaryButton } from 'components/shared/ui/styles/Global.styles';
import { useLocationGroups, useLocationQuery } from 'services/location/locationQueries';
import { loadEhiLocationCookie } from '@ehi/location';
import {
  accountSearchValidationSchema,
  getAccountSearchValuesInitialValues,
  transformAccountDetails,
} from 'components/flexFlow/rateAndBilling/section/rateSource/rateSourceDialog/accountSearchUtils';
import { DAILY_RENTAL, getGroupOptions } from 'utils/branchLookupUtils';
import {
  AccountSearchFields,
  AccountSearchValues,
} from 'components/flexFlow/rateAndBilling/section/rateSource/rateSourceDialog/AccountSearchTypes';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { getDefaultCountry } from 'utils/locationUtils';
import { AccountTypesDropDown } from 'components/flexFlow/rateAndBilling/section/rateSource/rateSourceDialog/AccountTypesDropDown';
import { useBusinessAccountSearch } from 'components/flexFlow/rateAndBilling/section/rateSource/rateSourceDialog/useBusinessAccountSearch';
import { Virtuoso } from 'react-virtuoso';
import { BusinessAccountCard } from 'components/flexFlow/rateAndBilling/section/rateSource/rateSourceDialog/BusinessAccountCard';
import { AccountListItem, StyledAccountsListBox } from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { modifyRateSource } from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { RateSource } from 'services/booking/bookingTypes';
import { useAlert } from 'components/shared/alert/AlertContext';
import { logError } from 'components/shared/logger/splunkLogger';

// Denotes the height of dialog footer and header along with the height of the account search section
export const ACCOUNT_LIST_OFFSET = 470;
// Denotes the percent offset of the dialog height. Currently, the dialog is set at 94%
export const DIALOG_HEIGHT_PERCENT_OFFSET = 0.06;

export type AccountSearchType = {
  handleCloseModal: () => void;
};

export const AccountSearch: FC<AccountSearchType> = ({ handleCloseModal }: AccountSearchType): JSX.Element => {
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const cookie = loadEhiLocationCookie();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const [scrollParent, setScrollParent] = useState<HTMLElement | undefined>(undefined);

  const { accounts, isFetchingAccounts, handleSearch, tooManyResults, noResults, handleReset } =
    useBusinessAccountSearch();

  const { data: locationData, isFetching: isLocationFetching } = useLocationQuery(cookie?.peoplesoftId ?? '');
  const defaultCountry = useMemo(() => getDefaultCountry(locationData), [locationData]);

  const { data: groups, isFetching: isGroupsFetching } = useLocationGroups(defaultCountry, DAILY_RENTAL);
  const groupOptions = useMemo(() => getGroupOptions(groups), [groups]);

  const resolver = useYupValidationResolver(accountSearchValidationSchema(t));

  const initialValues = useMemo(
    () => getAccountSearchValuesInitialValues(defaultCountry, groupOptions),
    [defaultCountry, groupOptions]
  );

  const formMethods = useForm({
    resolver,
    defaultValues: initialValues,
  });

  useEffect(() => {
    setScrollParent((window.document.getElementById('scrolling-view-accounts') as HTMLElement) ?? undefined);
  }, []);

  const [countrySelect, accountName, phoneNumber] = formMethods.watch([
    AccountSearchFields.Country,
    AccountSearchFields.AccountName,
    AccountSearchFields.PhoneNumber,
  ]);

  const onSubmit = async (values: AccountSearchValues) => {
    handleSearch(values, groupOptions);
  };

  const AccountCountHeader = () => {
    return (
      <Body2 color='textSecondary' data-testid='resultsCount' margin={ehiTheme.spacing(2)}>{`${
        accounts?.length ?? 0
      } ${t('rateAndBilling.results')}`}</Body2>
    );
  };

  const AccountFooter = () => {
    return (
      <Caption2 display={'flex'} justifyContent={'center'} paddingBottom={ehiTheme.spacing(5)}>
        {t('rateAndBilling.noMoreItems')}
      </Caption2>
    );
  };

  const handleLogError = useCallback((error: unknown, message: string) => {
    logError({ error, message: message });
  }, []);

  const handleSelect = useCallback(
    async (accountUrn: string) => {
      try {
        const rateSourceRequestBody: RateSource = {
          type: 'NEGOTIATED',
          account: accountUrn,
        };
        const { errors } = await updateAndRefresh(() => modifyRateSource(bookingEditorId, rateSourceRequestBody));
        if (!errors) {
          handleCloseModal();
        } else {
          await showAlert({
            variant: 'error',
            description: errors?.[0]?.localizedMessage || '',
          });
        }
      } catch (error) {
        handleLogError(error, `Unable to set account to editor id ${bookingEditorId}`);
      }
    },
    [bookingEditorId, handleCloseModal, handleLogError, showAlert, updateAndRefresh]
  );

  return (
    <FormProvider {...formMethods}>
      <Box padding={ehiTheme.spacing(2)} style={{ background: '#f5f5f5' }}>
        <GridContainer>
          <>
            <GridItem sm={6}>
              <CountrySearchDropDown defaultCountry={defaultCountry} name={AccountSearchFields.Country} required />
            </GridItem>
            <GridItem sm={6}>
              <GroupDropDown
                filedName={AccountSearchFields.Groups}
                countrySelect={countrySelect}
                bgColor='#f9f9f9'
                required
              />
            </GridItem>
          </>
          <>
            <GridItem sm={6}>
              <MaxLengthTextField
                name={AccountSearchFields.AccountName}
                autoFocus
                type='text'
                label={t('rateAndBilling.accountName')}
                maxLength={60}
              />
            </GridItem>
            <GridItem sm={6}>
              <AccountTypesDropDown accountTypeValues={initialValues[AccountSearchFields.AccountTypes]} />
            </GridItem>
          </>
          <GridItem sm={6}>
            <PhoneTextField
              country={countrySelect}
              name={AccountSearchFields.PhoneNumber}
              label={t('rateAndBilling.accountPhone')}
              data-testid='phone-number-field'
              className={piiField}
            />
          </GridItem>
        </GridContainer>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <EhiButton
            type={'reset'}
            onClick={() => {
              formMethods.reset();
              handleReset();
            }}>
            {t('common.reset')}
          </EhiButton>
          <PrimaryButton
            data-testid='searchAccount'
            disabled={
              (!phoneNumber && (accountName as string)?.length < 3) ||
              (!accountName && (phoneNumber as string)?.length < 4)
            }
            onClick={formMethods.handleSubmit(onSubmit)}>
            {t('common.search')}
          </PrimaryButton>
        </Box>
      </Box>
      <StyledAccountsListBox
        containerHeight={window.innerHeight - window.innerHeight * DIALOG_HEIGHT_PERCENT_OFFSET - ACCOUNT_LIST_OFFSET}>
        {accounts && accounts?.length > 0 && (
          <Virtuoso
            customScrollParent={scrollParent}
            totalCount={accounts.length}
            components={{ Header: AccountCountHeader, Item: AccountListItem, Footer: AccountFooter }}
            itemContent={(index: number) => {
              return (
                <BusinessAccountCard
                  account={transformAccountDetails(accounts[index])}
                  showDivider
                  showSelect
                  handleSelect={handleSelect}
                />
              );
            }}
          />
        )}
        {/* Update this section in GRES-928 */}
        {noResults && <>{t('search.noResults')}</>}
        {tooManyResults && <>Too many results</>}
      </StyledAccountsListBox>
      <ProgressOverlay inProgress={isLocationFetching || isGroupsFetching || isFetchingAccounts} />
    </FormProvider>
  );
};
